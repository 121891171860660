body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.smallImg{
  width: 150px;
}

.has-background-accent{
  background-color: #d10087;
}

.has-background-accent-dark{
  background-color: #0087d1;
}

.has-background-accent-light{
  background-color: #3db9fc;
}

.has-background-mango{
  background-color: #ba4b85;
}

.has-background-rollOn{
  background-color: #dbaa6c;
}

.has-background-softSkin{
  background-color: #83d0e1;
}

.has-background-helloKitty{
  background-color: #c3067a;
}

.has-background-coche{
  background-color: rgba(240,235,168, 1);
}

.has-background-aromatizante{
  background-color: rgb(245,144,32);
}

.has-background-axel{
  background-color: rgb(28,74,140);
}

.has-background-spray{
  background-color: rgb(253,218,127);
}

.has-background-liliana{
  background-color: rgb(90,22,107)
}

.box-shadow{
  box-shadow: 0 .5rem .5rem 0rem rgba(10,10,10,.2);
  transition: all .4s ease-in-out;
}

.box-shadow:hover{
  box-shadow: 0 3rem 3rem -1rem rgba(10,10,10,.2); 
  transform: scale(1.05);
}

.marginTop{
  margin-top: 25px !important;
}

.marginRight{
  margin-right: 30px !important;
}

.paddingCenter{
  padding: 10px;
}

.no-side-padding{
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 10px !important;
}
